/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBEBg1M9sdSMsEWImC3wwCUyonX8xW-qog",
  "appId": "1:554570259494:web:5f2a2a2de9a339ceaa9ae7",
  "authDomain": "schooldog-i-seminole-ga.firebaseapp.com",
  "measurementId": "G-GMGMP99439",
  "messagingSenderId": "554570259494",
  "project": "schooldog-i-seminole-ga",
  "projectId": "schooldog-i-seminole-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-seminole-ga.appspot.com"
}
